var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        [
          _c(
            "vs-row",
            { attrs: { "vs-type": "flex", "vs-justify": "center" } },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center"
                  }
                },
                [
                  _c("img", {
                    staticClass: "imag-blur",
                    attrs: {
                      src: require("../../assets/images/logo/neo.png"),
                      alt: ""
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c("vs-divider", { staticClass: "mb-base" }, [
            _c("h1", { staticClass: "text-primary" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Welcome.i18n) ||
                      _vm.resources.Welcome.name
                  )
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-1/2 md:w-1/4 xl:w-1/4" },
              [
                _c("statistics-card-line", {
                  staticClass: "mb-base",
                  attrs: {
                    hideChart: "",
                    icon: "MailIcon",
                    statistic: _vm.sent,
                    statisticTitle:
                      _vm.$t(_vm.resources.SentPresentations.i18n) ||
                      _vm.$t(_vm.resources.SentPresentations.name)
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-1/2 md:w-1/4 xl:w-1/4" },
              [
                _c("statistics-card-line", {
                  staticClass: "mb-base",
                  attrs: {
                    hideChart: "",
                    icon: "ActivityIcon",
                    statisticTitle:
                      _vm.$t(_vm.resources.OpenedPresentations.i18n) ||
                      _vm.$t(_vm.resources.OpenedPresentations.name),
                    statistic: _vm.opened,
                    color: "warning"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-1/2 md:w-1/4 xl:w-1/4" },
              [
                _c("statistics-card-line", {
                  staticClass: "mb-base",
                  attrs: {
                    hideChart: "",
                    icon: "UsersIcon",
                    statistic: _vm.newMembers,
                    statisticTitle:
                      _vm.$t(_vm.resources.NewMembers.i18n) ||
                      _vm.$t(_vm.resources.NewMembers.name),
                    color: "success"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-1/2 md:w-1/4 xl:w-1/4" },
              [
                _c("statistics-card-line", {
                  staticClass: "mb-base",
                  attrs: {
                    hideChart: "",
                    icon: "ClockIcon",
                    statistic: _vm.meeting,
                    statisticTitle:
                      _vm.$t(_vm.resources.WaitingForMeeting.i18n) ||
                      _vm.$t(_vm.resources.WaitingForMeeting.name),
                    color: "danger"
                  }
                })
              ],
              1
            )
          ]),
          _c("vs-divider", { staticClass: "mb-base" }),
          _c(
            "vs-row",
            { attrs: { "vs-type": "flex", "vs-justify": "center" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "mb-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "2",
                    "vs-sm": "6",
                    "vs-xs": "6"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "btn-group" },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: { size: "large" },
                          on: {
                            click: function($event) {
                              _vm.popupCreate = true
                            }
                          }
                        },
                        [
                          _c("vs-icon", {
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-send",
                              size: "large"
                            }
                          }),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(_vm.resources.SendNeo.i18n) ||
                                  _vm.resources.SendNeo.name
                              ) +
                              "\n          "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "vs-col",
                {
                  staticClass: "mb-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "2",
                    "vs-sm": "6",
                    "vs-xs": "6"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "btn-group" },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: { size: "large" },
                          on: {
                            click: function($event) {
                              _vm.popupLink = true
                            }
                          }
                        },
                        [
                          _c("vs-icon", {
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-link",
                              size: "large"
                            }
                          }),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(_vm.resources.GenerateLink.i18n) ||
                                  _vm.resources.GenerateLink.name
                              ) +
                              "\n          "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "vs-col",
                {
                  staticClass: "mb-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "2",
                    "vs-sm": "6",
                    "vs-xs": "6"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "btn-group" },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: { size: "large" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push({
                                path: "/tracer/guides"
                              })
                            }
                          }
                        },
                        [
                          _c("vs-icon", {
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-external-link",
                              size: "large"
                            }
                          }),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(_vm.resources.MyLinks.i18n) ||
                                  _vm.resources.MyLinks.name
                              ) +
                              "\n          "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "vs-col",
                {
                  staticClass: "mb-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "2",
                    "vs-sm": "6",
                    "vs-xs": "6"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "btn-group" },
                    [
                      _c(
                        "vs-button",
                        { attrs: { size: "large" } },
                        [
                          _c("vs-icon", {
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-git-pull-request",
                              size: "large"
                            }
                          }),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(_vm.resources.Genealogy.i18n) ||
                                  _vm.resources.Genealogy.name
                              ) +
                              "\n          "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "vs-col",
                {
                  staticClass: "mb-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "2",
                    "vs-sm": "6",
                    "vs-xs": "6"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "btn-group" },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: { size: "large" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push({ path: "/tracer/index" })
                            }
                          }
                        },
                        [
                          _c("vs-icon", {
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-search",
                              size: "large"
                            }
                          }),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(_vm.resources.Trackings.i18n) ||
                                  _vm.resources.Trackings.name
                              ) +
                              "\n          "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Create.i18n) || _vm.resources.Create.name,
            active: _vm.popupCreate
          },
          on: {
            "update:active": function($event) {
              _vm.popupCreate = $event
            }
          }
        },
        [
          _c(
            "ul",
            { staticClass: "centerx mb-1" },
            [
              _c(
                "vs-radio",
                {
                  attrs: { "vs-value": "2" },
                  model: {
                    value: _vm.typeTrace,
                    callback: function($$v) {
                      _vm.typeTrace = $$v
                    },
                    expression: "typeTrace"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Tracer.i18n) ||
                        _vm.resources.Tracer.name
                    )
                  )
                ]
              )
            ],
            1
          ),
          _c("br"),
          _c("p", { staticClass: "mb-base" }, [
            _vm._v(_vm._s(_vm.explicationtypeTrace))
          ]),
          _vm.divTypeTrace
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full mb-1" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-edit",
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.Description.i18n) ||
                            _vm.resources.Description.name
                        },
                        model: {
                          value: _vm.description,
                          callback: function($$v) {
                            _vm.description = $$v
                          },
                          expression: "description"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full mb-2" },
                    [
                      _c("vue-tags-input", {
                        staticClass: "w-full",
                        attrs: {
                          tags: _vm.tags,
                          placeholder:
                            _vm.$t(_vm.resources.Email.i18n) ||
                            _vm.resources.Email.name,
                          validation: _vm.validation
                        },
                        on: {
                          "tags-changed": function(newTags) {
                            return (_vm.tags = newTags)
                          }
                        },
                        model: {
                          value: _vm.tag,
                          callback: function($$v) {
                            _vm.tag = $$v
                          },
                          expression: "tag"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full mb-2" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-edit",
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.Subject.i18n) ||
                            _vm.resources.Subject.name
                        },
                        model: {
                          value: _vm.subject,
                          callback: function($$v) {
                            _vm.subject = $$v
                          },
                          expression: "subject"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-textarea", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.EmailText.i18n) ||
                            _vm.resources.EmailText.name
                        },
                        model: {
                          value: _vm.emailText,
                          callback: function($$v) {
                            _vm.emailText = $$v
                          },
                          expression: "emailText"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "mb-4 md:mb-0 ml-1",
                      on: {
                        click: function($event) {
                          return _vm.createGuide(false)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(_vm.resources.Send.i18n) ||
                              _vm.resources.Send.name
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("div", { staticClass: "vx-row" }, [
                    _c("div", { staticClass: "vx-col md:w-1/2 w-full mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-edit",
                              "icon-no-border": "",
                              label:
                                _vm.$t(_vm.resources.Name.i18n) ||
                                _vm.resources.Name.name
                            },
                            model: {
                              value: _vm.name,
                              callback: function($$v) {
                                _vm.name = $$v
                              },
                              expression: "name"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "vx-col md:w-1/2 w-full mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-edit",
                              "icon-no-border": "",
                              label:
                                _vm.$t(_vm.resources.LastName.i18n) ||
                                _vm.resources.LastName.name
                            },
                            model: {
                              value: _vm.lastName,
                              callback: function($$v) {
                                _vm.lastName = $$v
                              },
                              expression: "lastName"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/2 w-full mb-2 mt-2" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Country.i18n) ||
                                _vm.resources.Country.name
                            )
                          )
                        ]),
                        _c("v-select", {
                          attrs: {
                            clearable: false,
                            options: _vm.countryList,
                            reduce: function(country) {
                              return country.id
                            },
                            label: "name"
                          },
                          model: {
                            value: _vm.countryId,
                            callback: function($$v) {
                              _vm.countryId = $$v
                            },
                            expression: "countryId"
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "vx-col md:w-1/2 w-full mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-smartphone",
                              "icon-no-border": "",
                              label:
                                _vm.$t(_vm.resources.Mobile.i18n) ||
                                _vm.resources.Mobile.name
                            },
                            model: {
                              value: _vm.phone,
                              callback: function($$v) {
                                _vm.phone = $$v
                              },
                              expression: "phone"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-edit",
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.Subject.i18n) ||
                            _vm.resources.Subject.name
                        },
                        model: {
                          value: _vm.subject,
                          callback: function($$v) {
                            _vm.subject = $$v
                          },
                          expression: "subject"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full mb-2" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          type: "email",
                          "icon-pack": "feather",
                          icon: "icon-mail",
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.Email.i18n) ||
                            _vm.resources.Email.name
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-textarea", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.EmailText.i18n) ||
                            _vm.resources.EmailText.name
                        },
                        model: {
                          value: _vm.emailText,
                          callback: function($$v) {
                            _vm.emailText = $$v
                          },
                          expression: "emailText"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "mb-4 md:mb-0 ml-1",
                      on: {
                        click: function($event) {
                          return _vm.createGuide(true)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(_vm.resources.Send.i18n) ||
                              _vm.resources.Send.name
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.GenericLink.i18n) ||
              _vm.resources.GenericLink.name,
            active: _vm.popupLink
          },
          on: {
            "update:active": function($event) {
              _vm.popupLink = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mb-4" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-edit",
                    "icon-no-border": "",
                    label:
                      _vm.$t(_vm.resources.Description.i18n) ||
                      _vm.resources.Description.name
                  },
                  model: {
                    value: _vm.description,
                    callback: function($$v) {
                      _vm.description = $$v
                    },
                    expression: "description"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "vx-row mb-4" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mb-4 ml-4",
                  on: {
                    click: function($event) {
                      return _vm.genericLink()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t(_vm.resources.Generate.i18n) ||
                          _vm.resources.Generate.name
                      ) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-3/4 mb-4" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-link",
                    "icon-no-border": "",
                    disabled: ""
                  },
                  model: {
                    value: _vm.text,
                    callback: function($$v) {
                      _vm.text = $$v
                    },
                    expression: "text"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/4 mb-4" },
              [
                _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.text,
                        expression: "text",
                        arg: "copy"
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onCopy,
                        expression: "onCopy",
                        arg: "success"
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onError,
                        expression: "onError",
                        arg: "error"
                      }
                    ]
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Copy.i18n) ||
                          _vm.resources.Copy.name
                      )
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }