<template>
  <div>
    <vx-card>
      <vs-row vs-type="flex" vs-justify="center">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <img src="../../assets/images/logo/neo.png" alt class="imag-blur" />
        </vs-col>
      </vs-row>
      <vs-divider class="mb-base">
        <h1 class="text-primary">
          <strong>{{$t(resources.Welcome.i18n) || resources.Welcome.name}}</strong>
        </h1>
      </vs-divider>
      <div class="vx-row">
        <div class="vx-col w-1/2 md:w-1/4 xl:w-1/4">
          <statistics-card-line
            hideChart
            class="mb-base"
            icon="MailIcon"
            :statistic="sent"
            :statisticTitle="$t(resources.SentPresentations.i18n) || $t(resources.SentPresentations.name)"
          />
        </div>

        <div class="vx-col w-1/2 md:w-1/4 xl:w-1/4">
          <statistics-card-line
            hideChart
            class="mb-base"
            icon="ActivityIcon"
            :statisticTitle="$t(resources.OpenedPresentations.i18n) || $t(resources.OpenedPresentations.name)"
            :statistic="opened"
            color="warning"
          />
        </div>

        <div class="vx-col w-1/2 md:w-1/4 xl:w-1/4">
          <statistics-card-line
            hideChart
            class="mb-base"
            icon="UsersIcon"
            :statistic="newMembers"
            :statisticTitle="$t(resources.NewMembers.i18n) || $t(resources.NewMembers.name)"
            color="success"
          />
        </div>

        <div class="vx-col w-1/2 md:w-1/4 xl:w-1/4">
          <statistics-card-line
            hideChart
            class="mb-base"
            icon="ClockIcon"
            :statistic="meeting"
            :statisticTitle="$t(resources.WaitingForMeeting.i18n) || $t(resources.WaitingForMeeting.name)"
            color="danger"
          />
        </div>
      </div>

      <vs-divider class="mb-base"></vs-divider>

      <vs-row vs-type="flex" vs-justify="center">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="6"
          vs-xs="6"
          class="mb-2"
        >
          <div class="btn-group">
            <vs-button size="large" @click="popupCreate = true">
              <vs-icon icon-pack="feather" icon="icon-send" size="large"></vs-icon>
              <br />
              {{$t(resources.SendNeo.i18n) || resources.SendNeo.name}}
            </vs-button>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="6"
          vs-xs="6"
          class="mb-2"
        >
          <div class="btn-group">
            <vs-button size="large" @click="popupLink = true">
              <vs-icon icon-pack="feather" icon="icon-link" size="large"></vs-icon>
              <br />
              {{$t(resources.GenerateLink.i18n) || resources.GenerateLink.name}}
            </vs-button>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="6"
          vs-xs="6"
          class="mb-2"
        >
          <div class="btn-group">
            <vs-button size="large" @click="$router.push({ path: '/tracer/guides' })">
              <vs-icon icon-pack="feather" icon="icon-external-link" size="large"></vs-icon>
              <br />
              {{$t(resources.MyLinks.i18n) || resources.MyLinks.name}}
            </vs-button>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="6"
          vs-xs="6"
          class="mb-2"
        >
          <div class="btn-group">
            <vs-button size="large">
              <vs-icon icon-pack="feather" icon="icon-git-pull-request" size="large"></vs-icon>
              <br />
              {{$t(resources.Genealogy.i18n) || resources.Genealogy.name}}
            </vs-button>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="6"
          vs-xs="6"
          class="mb-2"
        >
          <div class="btn-group">
            <vs-button size="large" @click="$router.push({ path: '/tracer/index' })">
              <vs-icon icon-pack="feather" icon="icon-search" size="large"></vs-icon>
              <br />
              {{$t(resources.Trackings.i18n) || resources.Trackings.name}}
            </vs-button>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>

    <vs-popup
      :title="$t(resources.Create.i18n) || resources.Create.name"
      :active.sync="popupCreate"
    >
      <ul class="centerx mb-1">
        <!-- <vs-radio
          v-model="typeTrace"
          vs-value="1"
          class="mr-2"
        >{{ $t(resources.GenericLink.i18n) || resources.GenericLink.name }}</vs-radio>-->
        <vs-radio
          v-model="typeTrace"
          vs-value="2"
        >{{ $t(resources.Tracer.i18n) || resources.Tracer.name }}</vs-radio>
      </ul>
      <br />
      <p class="mb-base">{{ explicationtypeTrace }}</p>
      <div v-if="divTypeTrace">
        <div class="vx-col w-full mb-1">
          <vs-input
            v-model="description"
            class="w-full"
            icon-pack="feather"
            icon="icon-edit"
            icon-no-border
            :label="
              $t(resources.Description.i18n) || resources.Description.name
            "
          />
        </div>
        <div class="vx-col w-full mb-2">
          <vue-tags-input
            class="w-full"
            v-model="tag"
            :tags="tags"
            :placeholder="$t(resources.Email.i18n) || resources.Email.name"
            @tags-changed="newTags => (tags = newTags)"
            :validation="validation"
          />
        </div>
        <div class="vx-col w-full mb-2">
          <vs-input
            v-model="subject"
            class="w-full"
            icon-pack="feather"
            icon="icon-edit"
            icon-no-border
            :label="$t(resources.Subject.i18n) || resources.Subject.name"
          />
        </div>
        <div class="vx-col w-full">
          <vs-textarea
            v-model="emailText"
            class="w-full"
            icon-no-border
            :label="$t(resources.EmailText.i18n) || resources.EmailText.name"
          />
        </div>
        <vs-button class="mb-4 md:mb-0 ml-1" @click="createGuide(false)">
          {{
          $t(resources.Send.i18n) || resources.Send.name
          }}
        </vs-button>
      </div>
      <div v-else>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                v-model="name"
                class="w-full"
                icon-pack="feather"
                icon="icon-edit"
                icon-no-border
                :label="$t(resources.Name.i18n) || resources.Name.name"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                v-model="lastName"
                class="w-full"
                icon-pack="feather"
                icon="icon-edit"
                icon-no-border
                :label="$t(resources.LastName.i18n) || resources.LastName.name"
              />
            </div>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2 mt-2">
            <label class="vs-input--label">{{$t(resources.Country.i18n) || resources.Country.name}}</label>
            <v-select
              v-model="countryId"
              :clearable="false"
              :options="countryList"
              :reduce="country=> country.id"
              label="name"
            />
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                v-model="phone"
                class="w-full"
                icon-pack="feather"
                icon="icon-smartphone"
                icon-no-border
                :label="$t(resources.Mobile.i18n) || resources.Mobile.name"
              />
            </div>
          </div>
        </div>

        <div class="vx-col w-full">
          <vs-input
            v-model="subject"
            class="w-full"
            icon-pack="feather"
            icon="icon-edit"
            icon-no-border
            :label="$t(resources.Subject.i18n) || resources.Subject.name"
          />
        </div>

        <div class="vx-col w-full mb-2">
          <vs-input
            v-model="email"
            type="email"
            class="w-full"
            icon-pack="feather"
            icon="icon-mail"
            icon-no-border
            :label="$t(resources.Email.i18n) || resources.Email.name"
          />
        </div>
        <div class="vx-col w-full">
          <vs-textarea
            v-model="emailText"
            class="w-full"
            icon-no-border
            :label="$t(resources.EmailText.i18n) || resources.EmailText.name"
          />
        </div>
        <vs-button class="mb-4 md:mb-0 ml-1" @click="createGuide(true)">
          {{
          $t(resources.Send.i18n) || resources.Send.name
          }}
        </vs-button>
      </div>
    </vs-popup>

    <vs-popup
      :title="$t(resources.GenericLink.i18n) || resources.GenericLink.name"
      :active.sync="popupLink"
    >
      <div class="vx-row">
        <div class="vx-col w-full mb-4">
          <vs-input
            v-model="description"
            class="w-full"
            icon-pack="feather"
            icon="icon-edit"
            icon-no-border
            :label="
              $t(resources.Description.i18n) || resources.Description.name
            "
          />
        </div>
      </div>

      <div class="vx-row mb-4">
        <vs-button class="mb-4 ml-4" @click="genericLink()">
          {{
          $t(resources.Generate.i18n) || resources.Generate.name
          }}
        </vs-button>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full md:w-3/4 mb-4">
          <vs-input
            v-model="text"
            class="w-full"
            icon-pack="feather"
            icon="icon-link"
            icon-no-border
            disabled
          />
        </div>
        <div class="vx-col w-full md:w-1/4 mb-4">
          <vs-button
            v-clipboard:copy="text"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >{{$t(resources.Copy.i18n) || resources.Copy.name}}</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import axios from "axios";
import VueTagsInput from "@johmun/vue-tags-input";
import vSelect from "vue-select";

export default {
  components: { StatisticsCardLine, VueTagsInput, vSelect },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      sent: 0,
      opened: 0,
      newMembers: 0,
      meeting: 0,
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      popupCreate: false,
      popupLink: false,
      text: "",
      typeTrace: 2,
      countryList: [],
      guideId: 0,
      tag: "",
      tags: [],
      email: "",
      name: "",
      lastName: "",
      phone: "",
      emailText: "",
      description: "",
      subject: "",
      tracerId: 0,
      validation: [
        {
          classes: "min-length",
          rule: tag => tag.text.length < 8
        },
        {
          classes: "email"
          //rule: `/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/`
        }
      ],
      countryId: 0
    };
  },
  computed: {
    explicationtypeTrace() {
      if (this.typeTrace == 1) return this.$i18n.t("MsgTypeTraceGuide");
      else return this.$i18n.t("MsgTypeTracer");
    },
    divTypeTrace() {
      if (this.typeTrace == 1) return true;
      else return false;
    }
  },
  created() {
    this.getSummary();
    this.getCountries();
  },
  methods: {
    async getSummary() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_NEOAPI}Tracer/getSummary/${this.$i18n.locale}/${this.itaCode}`
      }).then(
        result => {
          if (result.data.length == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.sent = 0;
            this.opened = 0;
            this.newMembers = 0;
            this.meeting = 0;
          } else {
            let response = result.data[0];
            this.sent = response.sent;
            this.opened = response.open;
            this.newMembers = response.newMembers;
            this.meeting = response.meeting;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountries() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.countryList = [];
          } else {
            this.countryList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async createGuide(isTracer) {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_NEOAPI}Guide/Create`,
        data: {
          itaCode: this.itaCode,
          description: isTracer
            ? this.$i18n.t("Unclassified")
            : this.description,
          createdBy: JSON.parse(localStorage.getItem("userInfo")).email
        }
      }).then(
        result => {
          if (result.data.affectedRows == 1) {
            this.guideId = result.data.insertId;
            if (isTracer) {
              this.createTracer();
            } else {
              this.sendInvitation(true);
            }
          } else
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },

    async createTracer() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_NEOAPI}Tracer/Create`,
        data: {
          name: this.name,
          lastName: this.lastName,
          indicative: this.countryId,
          email: this.email,
          mobile: this.phone,
          guideId: this.guideId,
          createdBy: JSON.parse(localStorage.getItem("userInfo")).email,
          language: this.$i18n.locale
        }
      }).then(
        result => {
          if (result.data.affectedRows == 1) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check"
            });
            this.tracerId = result.data.insertId;
            this.sendInvitation(false);
          } else
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async sendInvitation(isOnlyGuide) {
      var emails = [];
      var url = `http://neo-tool.com/pages/presentation.html?id=${this.tracerId}`;
      if (isOnlyGuide) {
        url = `http://neo-tool.com/?id=${this.guideId}`;
        for (let index = 0; index < this.tags.length; index++) {
          emails.push(this.tags[index].text);
        }
      } else {
        emails.push(this.email);
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}NeoTool/Invitation`,
        data: {
          itaCode: this.itaCode,
          url: url,
          urltext: this.$i18n.t("VisitUs"),
          subject: this.subject,
          message: this.emailText,
          language: this.$i18n.locale.toUpperCase(),
          guestList: emails
        },
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          this.$vs.notify({
            title: this.$i18n.t("Success"),
            text: result.data.message,
            color: "success",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-check"
          });
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async genericLink() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_NEOAPI}Guide/Create`,
        data: {
          itaCode: this.itaCode,
          description: this.description,
          createdBy: JSON.parse(localStorage.getItem("userInfo")).email
        }
      }).then(
        result => {
          if (result.data.affectedRows == 1) {
            this.guideId = result.data.insertId;
            this.text = `https://neo-tool.com/?id=${this.guideId}`;
          } else
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    onCopy() {
      this.$vs.notify({
        title: this.$i18n.t("Success"),
        text: this.$i18n.t("MsgCopy"),
        color: "success",
        iconPack: "feather",
        position: "top-center",
        icon: "icon-check-circle"
      });
    },

    onError() {
      this.$vs.notify({
        title: this.$i18n.t("Error"),
        text: this.$i18n.t("MsgError"),
        color: "danger",
        iconPack: "feather",
        position: "top-center",
        icon: "icon-alert-circle"
      });
    }
  }
};
</script>
<style lang="css">
.vue-tags-input[data-v-61d92e31] {
  max-width: 100%;
  position: relative;
  background-color: #fff;
}

.vue-tags-input .ti-input {
  padding: 4px 10px;
  transition: border-bottom 200ms ease;
}

/* we cange the border color if the user focuses the input */
.vue-tags-input.ti-focus .ti-input {
  border: 1px solid #ebde6e;
}

/* some stylings for the autocomplete layer */
.vue-tags-input .ti-autocomplete {
  background: #283944;
  border: 1px solid #8b9396;
  border-top: none;
}

/* the selected item in the autocomplete layer, should be highlighted */
.vue-tags-input .ti-item.ti-selected-item {
  background: #ebde6e;
  color: #283944;
}

/* style the placeholders color across all browser */
.vue-tags-input ::-webkit-input-placeholder {
  color: #626262;
}

.vue-tags-input ::-moz-placeholder {
  color: #626262;
}

.vue-tags-input :-ms-input-placeholder {
  color: #626262;
}

.vue-tags-input :-moz-placeholder {
  color: #626262;
}

/* default styles for all the tags */
.vue-tags-input .ti-tag {
  position: relative;
  background: #ebde6e;
  color: #283944;
}

/* we defined a custom css class in the data model, now we are using it to style the tag */
.vue-tags-input .ti-tag.custom-class {
  background: transparent;
  border: 1px solid #ebde6e;
  color: #ebde6e;
  margin-right: 4px;
  border-radius: 0px;
  font-size: 13px;
}

/* the styles if a tag is invalid */
.vue-tags-input .ti-tag.ti-invalid {
  background-color: #e88a74;
}

/* if the user input is invalid, the input color should be red */
.vue-tags-input .ti-new-tag-input.ti-invalid {
  color: #e88a74;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
  text-decoration: line-through;
}

/* if the user presses backspace, the complete tag should be crossed out, to mark it for deletion */
.vue-tags-input .ti-tag:after {
  transition: transform 0.2s;
  position: absolute;
  content: "";
  height: 2px;
  width: 108%;
  left: -4%;
  top: calc(50% - 1px);
  background-color: #000;
  transform: scaleX(0);
}

.vue-tags-input .ti-deletion-mark:after {
  transform: scaleX(1);
}
</style>
